"use client"

import styled, { css } from 'styled-components';
import { Wrapper as Divider } from '@/shared/ui/Divider/styled';
import { Paragraph, Section, SectionContent } from '@/shared/ui/styled';

export const Wrapper = styled.div<{ $disableContentPadding?: boolean }>`
  flex: 1;

  > ${Section}:first-of-type {
    padding-top: 50px;
    margin-top: 0;

    ${SectionContent} {
      ${Paragraph} {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    ${Divider} {
      margin: 25px 0;
    }

    ${Section} {
      margin: 25px 0;
    }

    &:has(${Divider}) {
      ${Section} {
        margin-bottom: 0;
      }
    }
  }

  ${(props) =>
    props.$disableContentPadding &&
    css`
      ${Divider} {
        margin-bottom: 0;
      }
    `}
`;

'use client';

import React from 'react';
import InnerPageLayout from '@/layouts/InnerPageLayout';
import Button from '@/shared/ui/Button';
import { Paragraph } from '@/shared/ui/styled';

export default function Error({ error, reset }: { error: Error; reset: () => void }) {

  return (
    <InnerPageLayout pageTitle="Something went wrong.">
      <Paragraph>Looks like an error occured while processing this page, our team already working on it!</Paragraph>
      <Paragraph>
        <b>Error message:</b> {error.message}
      </Paragraph>
      <Button label="Try again" onClick={reset} />
    </InnerPageLayout>
  );
}

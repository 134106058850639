"use client"

import React from 'react';
import { Wrapper } from './styled';

function Divider({ size = 50 }: { size?: number }) {
  return <Wrapper $size={size} />;
}

export { Divider };

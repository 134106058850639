'use client';

import React from 'react';
import Footer from '@/widgets/Footer';
import Navbar from '@/widgets/Navbar';
import { Container, PageTitle, Paragraph, Section, SectionContent } from '@/shared/ui/styled';
import Divider from '@/shared/ui/Divider';
import { Wrapper } from './styled';

type InnerPageLayoutProps = {
  pageTitle: string | React.ReactNode;
  pageText?: string | React.ReactNode;
  children?: React.ReactNode;
  disableContentPadding?: boolean;
};

function InnerPageLayout({ children, pageTitle, pageText, disableContentPadding }: InnerPageLayoutProps) {
  return (
    <>
      <Navbar />
      <Wrapper $disableContentPadding={disableContentPadding}>
        <Section>
          <PageTitle>{pageTitle}</PageTitle>
          {pageText && (
            <SectionContent>
              <Paragraph>{pageText}</Paragraph>
            </SectionContent>
          )}
        </Section>
        <Container style={{ marginBottom: 50 }}>
          <Divider />
          {children}
        </Container>
      </Wrapper>

      <Footer />
    </>
  );
}

export { InnerPageLayout, type InnerPageLayoutProps };
